import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Log } from '../../Common/Log';
import { Texts } from '../../Common/Texts';

export function ClockValidatorApp(containerQuery: string) {
    const elm = document.querySelector(containerQuery);
    const root = createRoot(elm);
    root.render(<ClockValidator />);
}

function ClockValidator() {
    const [showWarning, setShowWarning] = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            const data = await fetch('/api/health/time');
            const serverTime = new Date(await data.json()).getTime();
            const clientTime = new Date().getTime();
            const diffMs = Math.abs(serverTime - clientTime);
            const twoMinutesInMs = 1000 * 60 * 2;
            setShowWarning(diffMs >= twoMinutesInMs);
        };

        fetchData().catch(Log.error);
    });

    return (
        showWarning && (
            <div className="bg-secondary-red-extralight p-4 text-center text-secondary-red-dark">
                {Texts.get('ClockValidatorError')}
            </div>
        )
    );
}
